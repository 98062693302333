<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="mini-spinner"
    />
    <!-- <ServerError v-if="ServerError" /> -->
    <v-layout wrap>
      <v-flex xs12 sm8>
        <span class="sbold" style="font-size: 25px"> REPORTS </span>
      </v-flex>
      <!-- <v-flex xs12 sm2 pb-0 pb-sm-2 pr-0 pr-sm-5>
        <v-select
          clearable
          :items="animalTypes"
          v-model="animalType"
          outlined
          label="animal Type"
          color="primary"
          dense
          class="rounded"
        >
        </v-select>
      </v-flex> -->
      <v-flex xs12 sm2 pb-0 pb-sm-2 pr-0 pr-sm-5>
        <v-select
          clearable
          :items="spotTypes"
          v-model="spotType"
          outlined
          label="Spot Type"
          color="primary"
          dense
          class="rounded"
        >
        </v-select>
      </v-flex>
      <v-flex xs12 sm2 pb-2>
        <download-excel :data="myData" :fields="json_fields">
          <v-btn
            width="155px"
            dark
            style="text-transform: none"
            color="primary"
            class="hidden-xs-only sbold"
            >Download Excel</v-btn
          >
          <v-btn
            width="155px"
            dark
            block
            class="hidden-sm-and-up sbold"
            style="text-transform: none"
            color="primary"
            >Download Excel</v-btn
          >
        </download-excel>
      </v-flex>
      <!-- <v-flex xs12 sm2 pb-2>
        <a
          target="_blank"
          :href="'http://whalewatch.leopardtechlabs.in' + '/pdf/report'"
          download="PDF"
          style="text-decoration: none !important"
        >
          <v-btn
            width="155px"
            dark
            style="text-transform: none"
            color="primary"
            class="hidden-xs-only sbold"
            >Download PDF</v-btn
          >
          <v-btn
            width="155px"
            dark
            block
            class="hidden-sm-and-up sbold"
            style="text-transform: none"
            color="#81C784"
            >Download PDF</v-btn
          >
        </a>
      </v-flex> -->
      <v-flex xs12 v-if="report" pa-2>
        <v-card outlined class="sbold">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left sbold" style="font-size: 15px">
                    Sr No.
                  </th>
                  <th class="text-left sbold" style="font-size: 15px">
                    Netted or Free
                  </th>
                  <th class="text-left sbold" style="font-size: 15px">Date</th>
                  <th class="text-left sbold" style="font-size: 15px">
                    Name of user
                  </th>

                  <th class="text-left sbold" style="font-size: 15px">
                    Ph.Number
                  </th>
                  <th class="text-left sbold" style="font-size: 15px">
                    Latitude
                  </th>
                  <th class="text-left sbold" style="font-size: 15px">
                    Longitude
                  </th>
                  <th class="text-left sbold" style="font-size: 15px">
                    Gender
                  </th>
                   <th class="text-left sbold" style="font-size: 15px">
                    Length (M)
                  </th>
                  <th class="text-left sbold" style="font-size: 15px">
                    Boat name
                  </th>
                  <th class="text-left sbold" style="font-size: 15px">
                    Boat number
                  </th>
                  <th class="text-left sbold" style="font-size: 15px">
                    Owner name
                  </th>
                  <th class="text-left sbold" style="font-size: 15px">
                    Condition
                  </th>
                  <th class="text-left sbold" style="font-size: 15px">
                    Other info
                  </th>
                  <th class="text-left sbold" style="font-size: 15px">
                    Photos/Videos and more..
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, i) in report" :key="i">
                  <!-- <td>{{ i + 1 }}</td> -->
                  <td>
                    <span v-if="page == 1">
                      {{ i + 1 }}
                    </span>
                    <span v-else>
                      {{ i + 1 + 20 * (page - 1) }}
                    </span>
                  </td>

                  <td v-if="value.field">
                    {{ value.field }}
                  </td>
                  <td v-if="value.create_date">
                    <!-- {{ formatDate(value.create_date) }} -->
                    {{ value.create_date.slice(0, 10) }}
                  </td>
                  <td v-if="value.userid">
                    <router-link
                      style="text-decoration: none; color: black"
                      :to="'/uploaddetail?id=' + value._id"
                    >
                      {{ value.userid.first_name
                      }}{{ value.userid.last_name }}</router-link
                    >
                  </td>
                  <td>
                    {{ value.userid.phone }}
                  </td>

                  <td v-if="value.location[1]">{{ value.location[1] }}</td>
                  <td v-else>-</td>
                  <td v-if="value.location[0]">{{ value.location[0] }}</td>
                  <td v-else>-</td>
                  <td v-if="value.gender">{{ value.gender }}</td>
                  <td v-else>-</td>
                   <td v-if="value.length">{{ value.length }}</td>
                  <td v-else>-</td>
                  <td v-if="value.boatname">{{ value.boatname }}</td>
                  <td v-else>-</td>

                  <td v-if="value.boatno">{{ value.boatno }}</td>
                  <td v-else>-</td>
                  <td v-if="value.ownerName">{{ value.ownerName }}</td>
                  <td v-else>-</td>
                  <td v-if="value.health">{{ value.health }}</td>
                  <td v-else>-</td>
                  <td v-if="value.description">{{ value.description }}</td>
                  <td v-else>-</td>

                  <td class="text-center">
                    <router-link
                      style="text-decoration: none; color: black"
                      :to="'/uploaddetail?id=' + value._id"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-flex>
      <v-layout wrap pt-2>
        <v-flex xs12>
          <v-pagination
            small
            color="primary"
            total-visible="7"
            v-model="page"
            :length="Pagelength"
          >
          </v-pagination>
        </v-flex>
      </v-layout>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
// import store from "../../store";
export default {
  data() {
    return {
      curItem: {},
      dialog: false,
      appLoading: false,
      ServerError: false,
      animalTypes: ["Shark", "megafauna"],
      animalType: null,
      spotTypes: ["In Net", "In Natural Water"],
      spotType: null,
      report: [],
      page: 1,
      Pagelength: 0,
      // download excel
      myData: [],
      excel: {
        spottype: "",
        date: "",
        username: "",
        Phnumber: "",
        lat: "",
        lon: "",
        Gender: "",
        bname: "",
        bnumber: "",
        boname: "",
        condition: "",
        Other_info: "",
        Mlength:""
      },

      json_fields: {
        Netted_or_Free: "spottype",
        Date: "date",
        Name_of_user: "username",
        Phn_Number: "Phnumber",
        Latitude: "lat",
        Longitude: "lon",
        Gender: "Gender",
        Length_in_Meter:"Mlength",
        Boat_name: "bname",
        Boat_number: "bnumber",
        Boat_Owner_name: "boname",
        Condition: "condition",
        Other_info: "Other_info",
        // photos: "photo",
      },
    };
  },
  mounted() {
    this.getData();
    this.getExcel();
  },
  watch: {
    page() {
      // this.getData();
      this.getData();
      this.getExcel();
    },

    animalType() {
      this.getData();
    },
    spotType() {
      this.getData();
    },
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/admin/view/all/reports",
        method: "get",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          page: this.page,
          limit: 20,
          type: this.animalType,
          field: this.spotType,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.data) {
            this.report = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getPdf() {
      this.appLoading = true;
      axios({
        url: "pdf/report",
        method: "get",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.data) {
            // this.report = response.data.data;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getExcel() {
      this.appLoading = true;
      this.myData = [];

      axios({
        url: "/admin/view/all/reports",
        method: "get",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.data) {
            // this.report = response.data.data;
            this.myData = [];
            // this.Pagelength = Math.ceil(response.data.totalLength / 20);
            //excel
            for (let i = 0; i < response.data.data.length; i++) {
              if (response.data.data[i].field) {
                this.excel.spottype = response.data.data[i].field;
              }
              if (response.data.data[i].create_date) {
                this.excel.date = this.formatDate(
                  response.data.data[i].create_date
                );
              }
              if (response.data.data[i].userid.first_name) {
                this.excel.username =
                  response.data.data[i].userid.first_name +
                  " " +
                  response.data.data[i].userid.last_name;
              }

              if (response.data.data[i].userid.phone) {
                this.excel.Phnumber = response.data.data[i].userid.phone;
              }
              if (response.data.data[i].location[1]) {
                this.excel.lat = response.data.data[i].location[1];
              }
              if (response.data.data[i].location[0]) {
                this.excel.lon = response.data.data[i].location[0];
              }

              if (response.data.data[i].gender) {
                this.excel.Gender = response.data.data[i].gender;
              }
                if (response.data.data[i].length) {
                this.excel.Mlength = response.data.data[i].length;
              }

              if (response.data.data[i].boatname) {
                this.excel.bname = response.data.data[i].boatname;
              }
              if (response.data.data[i].boatno) {
                this.excel.bnumber = response.data.data[i].boatno;
              }
              if (response.data.data[i].ownerName) {
                this.excel.boname = response.data.data[i].ownerName;
              }

              if (response.data.data[i].health) {
                this.excel.condition = response.data.data[i].health;
              }
              if (response.data.data[i].description) {
                this.excel.Other_info = response.data.data[i].description;
              }

              // if (response.data.data[i].photos) {
              //   for (let k = 0; k < response.data.data[i].photos.length; k++) {
              //     this.excel.photo.push(
              //       JSON.parse(
              //         JSON.stringify(
              //           "http://whalewatch.leopardtechlabs.in/file/get/" +
              //             response.data.data[i].photos[k] +
              //             ","
              //         )
              //       )
              //     );
              //   }
              // }
              //   if (response.data.data[i].photos) {
              //   this.excel.photo = "http://whalewatch.leopardtechlabs.in/file/get/" + response.data.data[i].photos[0];
              // }

              this.myData.push(JSON.parse(JSON.stringify(this.excel)));
              this.excel = {};
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " , " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;

      return strTime;
    },
  },
};
</script>
